import  React  from "react"
import styled  from "styled-components"
import tw  from "tailwind.macro"
import { useStaticQuery, graphql } from "gatsby"
import { HeaderText3, GrayTextWrapper, BodyText } from "../elements/shared/texts";
import { UseGlobalStateContext } from "../context/globalContext"
import SubscriptionComponent from "./SubscriptionComponent"

const Container = styled.div`
 ${tw`flex justify-center px-4 items-center md:h-screen h-auto`};
  width: min(1400px, 100%);
  margin: 0 auto;
  overflow: hidden;
  
`

const BannerImage = styled.div`
  ${tw`hidden md:block`};
  min-width: 50%;
  height: auto;
  z-index: 0;

`


const MobileBannerImage = styled.div`
  ${tw`md:hidden block`};
  min-width: 50%;
  height: auto;
`




const MainHeaderText = styled.div`
  ${tw`text-left  mb-10 md:my-0 mt-32 `};
  width: min(90%, 500px);
  
`

const Header = () => {
  const { currentTheme } = UseGlobalStateContext()
  const data = useStaticQuery(
    graphql`
      query {
        banner: file(relativePath: {eq: "banner 7.svg"}) {
            publicURL
        }
      }
    `
  )

  return (
    <Container >
      <div className="w-full flex items-center justify-between text-center z-10 xl:px-0 lg:px-8 md:px-6 px-4">
            <MainHeaderText>
              <GrayTextWrapper>
                <HeaderText3>BUILDING REAL APPS FOR <HeaderText3 isPrimary> FREE </HeaderText3></HeaderText3>
                <BodyText>Access everything for free with an optional support subscription.</BodyText>
              </GrayTextWrapper>
              <SubscriptionComponent isHome />
            </MainHeaderText>
        <BannerImage >
          <img  loading={"lazy"} src={data.banner.publicURL} alt={"Banner image"} />
        </BannerImage>
      </div>
    </Container>
  )
}

export default Header
